<template>
  <div class="wrapper">
    <header>
      <div class="header-container">
        <h1 style="color: white; margin: 0px 0px;"> Semantic Search </h1>
        <div class="search-container">
          <input type="text" placeholder="Search..." class="search-input" v-model="searchQuery" v-on:keyup.enter="performSearch">
          <span class="pi pi-search" style="color: black; margin-left: -40px;"></span>
        </div>
        <div class="settings-container">
          <label for="limit">Limit:</label>
          <input type="number" placeholder="Limit" v-model="limit" style="width: 30px;"> 

          <label for="candidates">Candidates:</label>
          <input type="number" placeholder="Candidates" v-model="numCandidates" style="width: 50px;">

          <label for="index">Similarity:</label>
          <select v-model="index">
            <option value="all-mpnet-base-v2">dot</option>
            <option value="all-mpnet-base-v2-euclidean">euclidean</option>
            <option value="all-mpnet-base-v2-cosine">cosine</option>
          </select>
        </div>
      </div>
    </header>
    <div class="results-container">
      <div class="result-card" v-for="result in results" :key="result.id">
        <h2>{{ result.name }}</h2>
        <span v-html="result.description"></span>
        <img v-if="result.images.length > 0" :src="result.images[0].src" :alt="result.title" />
        <span style="font-weight: bold;">Score: {{ result.score }}</span>
        <div v-if="result.showSlider">
          <!-- Slider for adjusting score -->
          <input type="range" min="0" max="1" step="0.01" v-model="result.adjustedScore">
          <span>{{ result.adjustedScore }}</span><br>
          <button @click="submitScore(result)">Save</button>
        </div>
        <br>
        <button v-if="!result.showSlider" @click="showSlider(result)">Edit score</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      searchQuery: '',
      results: [],
      numCandidates: 1000,
      limit: 8,
      index: 'all-mpnet-base-v2',
    };
  },
  methods: {
    showSlider(result) {
      // Initialize adjustedScore if it doesn't exist
      if (result.adjustedScore === undefined) {
        result.adjustedScore = result.score; 
      }
      // Toggle visibility of the slider
      result.showSlider = !result.showSlider;
    },
    async submitScore(result) {
      let payload = {
        'query': this.searchQuery,
        'product_id': result.id,
        'index': this.index,
        'old_score': result.score,
        'new_score': parseFloat(result.adjustedScore),
        'timestamp': new Date().toISOString(),
      }
      result.score = result.adjustedScore;
      console.log('Submitting new score for:', result.name, 'New score:', result.adjustedScore);
      // Implement your submission logic here
      // Hide the slider after submission
      result.showSlider = false;
      let base_url = 'https://ai.maco.io/api/training/sample';
      if (this.appMode === 'development') {
        base_url = 'http://127.0.0.1:3001/api/training/sample';
      }
      try {
        const response = await fetch(base_url, {
          method: 'POST',
          headers: {
            'Authorization': 'Basic bWFjbzptYWNv',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload),
        }); 
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        console.log(data); // Handle the response data
        } catch (error) {
          console.error('Error fetching data: ', error);
        }
    },
    async correctScore(product) {
      let base_url = 'https://ai.maco.io/api/training/sample';
      if (this.appMode === 'development') {
        base_url = 'http://127.0.0.1:3001/api/training/sample';
      }
      let payload = {
        query: this.searchQuery,
        product_id: product.id,
        index: this.index,
        old_score: product.score,
        new_score: parseFloat(prompt('Enter the new score (1 is good, 0 is bad)', product.score)),
      };
      try {
        const response = await fetch(base_url, {
          method: 'POST',
          headers: {
            'Authorization': 'Basic bWFjbzptYWNv',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload),
        }); 
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        console.log(data); // Handle the response data
        } catch (error) {
          console.error('Error fetching data: ', error);
        }
    },
    async performSearch() {
      const query = this.searchQuery;
      let base_url = 'https://ai.maco.io/api/search';
      if (this.appMode === 'development') {
        base_url = 'http://127.0.0.1:3001/api/search';
      }
      try {
        const response = await fetch(base_url + `?query=${encodeURIComponent(query)}&limit=` + this.limit + '&num_candidates=' + this.numCandidates + '&index=' + this.index, {
          method: 'GET',
          // mode: 'no-cors',
          headers: {
            'Authorization': 'Basic bWFjbzptYWNv',
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        console.log(data); // Handle the response data
        this.results = data;
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    }
  },
  computed: {
    appMode() {
      return process.env.NODE_ENV;
    }
  },
};
</script>


<style scoped>
.wrapper {
  font-family: Arial, sans-serif;
}

header {
  line-height: 1.5;
  font-size: 1.5rem;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: black;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  color: white;
}

.search-container {
  display: flex;
  margin-top: 10px;
}

.search-input {
  padding: 12px;
  margin-top: -6px;
  width: 400px; /* Adjust as needed */
  border-radius: 8px;
}

.settings-container {
  display: flex;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: -10px;
}

.settings-container > input, select {
  margin: 0 10px;
  border-radius: 8px;
  padding: 0px 8px;
}

.results-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* This creates a responsive grid */
  grid-gap: 20px;
  padding: 200px 20px 20px; /* Adjust top padding to ensure it doesn't overlap the header */
  margin: 0 auto;
  max-width: 1200px; /* Adjust as needed */
  color: #333;
}

.result-card {
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
  padding: 20px;
  border-radius: 8px;
}

.result-card img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-top: 10px;
}

</style>
